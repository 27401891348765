import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'realResetAnswersButton',
    'questionsTable',
    'firstName',
    'lastName',
    'email',
    'creditFormSubmit',
    'creditReason',
    'creditAmount',
    'choiceFilterSelect',
    'blacklistStatusFilter',
    'countryFlag',
  ];

  connect() {
    this.url = new URL(window.location.href);
    this.searchParams = this.url.searchParams;
    if (/question_query|choice_filter/.test(this.url.search)) {
      if (this.hasChoiceFilterSelectTarget) {
        this.choiceFilterSelectTarget.value =
          this.url.searchParams.get('choice_filter') || 'all';
      }

      if (this.hasQuestionsTableTarget) {
        let element = this.questionsTableTarget;
        this.smoothScroll(element);
      }
    }

    if (this.hasBlacklistStatusFilterTarget) {
      this.blacklistStatusFilterTarget.value =
        this.url.searchParams.get('user_status') || 'all';
    }

    if (this.hasCountryFlagTarget) {
      if (this.countryFlagTarget.dataset.isPirate) {
        let panelHeader = document.querySelector('.panel-header.callout');
        panelHeader.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
      }
    }
  }

  searchUsers() {
    this.searchParams.delete('page');
    this.set_users_search_params();
    window.location = decodeURIComponent(this.url.toString());
  }

  set_users_search_params() {
    this.searchParams.delete('page_answers');
    this.url.pathname = '/admin';
    let firstNameSearch = this.firstNameTarget.value;
    let lastNameSearch = this.lastNameTarget.value;
    let emailSearch = this.emailTarget.value;

    if (firstNameSearch) {
      this.searchParams.set('first_name', firstNameSearch);
    } else {
      this.searchParams.delete('first_name');
    }

    if (lastNameSearch) {
      this.searchParams.set('last_name', lastNameSearch);
    } else {
      this.searchParams.delete('last_name');
    }

    if (emailSearch) {
      this.searchParams.set('email', emailSearch);
    } else {
      this.searchParams.delete('email');
    }
  }

  sortByAttribute(event) {
    this.searchParams.delete('page');
    let attribute = event.target.dataset.colName;
    if (this.searchParams.get('sort') === `-${attribute}`) {
      this.searchParams.set('sort', attribute);
    } else {
      this.searchParams.set('sort', `-${attribute}`);
    }
    window.location = decodeURIComponent(this.url.toString());
  }

  filterAnswers(e) {
    this.searchParams.delete('page_answers');
    this.searchParams.set('choice_filter', e.target.value);
    window.location = decodeURIComponent(this.url.toString());
  }

  setFrozenReason(event) {
    this.patch(`/api/v2/admin/users/${event.target.dataset.userId}`, {
      frozen_reason: event.target.value,
    }).catch(error => console.log('error: ', error));
  }

  clearAdminSearch() {
    window.location = this.url.pathname;
  }

  validateCreditForm() {
    if (this.creditAmountTarget.value && this.creditReasonTarget.value) {
      if (
        this.creditAmountTarget.value > 0 &&
        this.creditAmountTarget.value <= 5
      ) {
        this.creditFormSubmitTarget.disabled = false;
      } else {
        this.creditFormSubmitTarget.disabled = true;
      }
    } else {
      this.creditFormSubmitTarget.disabled = true;
    }
  }

  filterBlacklistUsers(e) {
    this.searchParams.set('user_status', e.target.value);
    window.location = decodeURIComponent(this.url.toString());
  }

  requestHeaders() {
    return new Headers({
      'x-csrf-token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
      'content-type': 'application/json',
    });
  }

  patch(url = '', data = {}) {
    return fetch(url, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: this.requestHeaders(),
      body: JSON.stringify(data),
    }).then(response => response.json());
  }

  smoothScroll(element) {
    if (!element) return;
    setTimeout(() => {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 100);
  }
}

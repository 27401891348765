import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['searchInput', 'securityTokenInput', 'statusFilter'];

  connect() {
    this.url = new URL(window.location.href);
    this.searchParams = this.url.searchParams;
    const filterParam = this.searchParams.get('test_status');
    if (this.hasStatusFilterTarget && filterParam) {
      this.statusFilterTarget.value = filterParam;
    }
    this.statusFilterTarget.classList.remove('hide');
  }

  // only search one input at a time
  clearOtherInput(event) {
    if (event.target === this.searchInputTarget)
      this.securityTokenInputTarget.value = '';
    if (event.target === this.securityTokenInputTarget)
      this.searchInputTarget.value = '';
  }

  filterTests(e) {
    this.searchParams.set('test_status', e.target.value);
    this.url.pathname = '/admin/tests';
    window.location = decodeURIComponent(this.url.toString());
  }
}

import { Controller } from "stimulus"
import fetchMixin from '../dashboard/signUp/fetchMixin.js';
export default class extends Controller {
  static targets = [
    'unsubscribeBody',
    'unsubscribedContainer',
    'unsubscribeError',
    'epicSpinner'
  ]

  connect() {
    let securityToken = this.unsubscribeBodyTarget.dataset.tokenValue;
    this.unsubscribeFromTestEmails(securityToken);
  }

  unsubscribeFromTestEmails(securityToken) {
    fetchMixin.methods
      .post(`/unsubscribes/${securityToken}/confirm`)
      .then(res => {
        if (res.status == 200) {
          this.showUnsubscribed();
        } else {
          this.showFailedToUnsubscribe();
        }
      })
      .catch(error => {
        this.showFailedToUnsubscribe();
        console.log(error);
      });
  }

  showFailedToUnsubscribe() {
    this.unsubscribedContainerTarget.classList.add('hide');
    this.unsubscribeErrorTarget.classList.remove('hide');
    this.showBody();
  }

  showUnsubscribed() {
    this.unsubscribedContainerTarget.classList.remove('hide');
    this.unsubscribeErrorTarget.classList.add('hide');
    this.showBody();
  }

  showBody() {
    this.epicSpinnerTarget.classList.add('hide');
    this.unsubscribeBodyTarget.classList.remove('hide');
  }

}

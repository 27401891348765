import { Controller } from 'stimulus';
import Noty from 'noty';

export default class extends Controller {
  static targets = [
    'testDuringTimes',
    'testAvailability',
    'offlineStopAt',
    'offlineStartAt',
    'testAvailabilityErrors',
    'flashMessages',
    'devices',
    'testAvailabilitySection',
  ];

  connect() {
    this.handleNotifications();
    this.toggleTestAvailability();
  }

  handleNotifications() {
    let errors = JSON.parse(this.flashMessagesTarget.dataset.errors);
    if (errors) {
      this.showNoty('Error Updating Your Profile!', 'error');
      return this.scrollToFirstError(errors);
    }

    let message = JSON.parse(this.flashMessagesTarget.dataset.message);
    if (message && message.success) {
      return this.showNoty(message.success, 'success');
    }
    if (message && message.warning) {
      return this.showNoty(message.warning, 'warning');
    }
  }

  toggleTestAvailability() {
    if (this.testDuringTimesTarget.checked) {
      this.testAvailabilityTarget.classList.add('is-active');
      this.testAvailabilityErrorsTarget.classList.remove('is-active');
    } else {
      this.testAvailabilityTarget.classList.remove('is-active');
    }
  }

  scrollToFirstError(errors) {
    if (errors.testing_device.length)
      return this.smoothScroll(this.devicesTarget);
    if (errors.offline_start_at.length || errors.offline_stop_at.length) {
      return this.smoothScroll(this.testAvailabilitySectionTarget);
    }
  }

  smoothScroll(element) {
    setTimeout(() => {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 100);
  }

  showNoty(message, type) {
    new Noty({
      type: type,
      theme: 'relax',
      layout: 'topRight',
      container: '.noty-container',
      text: message,
      timeout: '4000',
      closeWith: ['click'],
      killer: true,
    }).show();
  }

  disableSubmitonEnter(event) {
    if (
      event.key === 13 ||
      event.keyIdentifier === 13 ||
      event.keyCode === 13
    ) {
      event.preventDefault();
      return false;
    }
  }
}

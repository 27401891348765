import { Controller } from 'stimulus'
import Tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'modalOverlay',
    'aboutPaymentsModal',
    'modalClose'
  ]

  connect() {
     Tippy('[data-tippy-content]', {
       animation: 'fade',
       arrow: true
     });
   }

  handleShowAboutPaymentsModal() {
    this.aboutPaymentsModalTarget.style.display = 'block';
    this.modalOverlayTarget.style.display = 'block';
  }

  handlePaymentModalClick(event) {
    event.stopPropagation();
  }

  handleExitModal(event) {
    this.closeModal();
  }

  closeModal() {
    this.aboutPaymentsModalTarget.style.display= 'none';
    this.modalOverlayTarget.style.display= 'none';
  }
}

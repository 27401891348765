import { Controller } from "stimulus"
import scrollIt from "../lib/scroll.js"
import "particles.js/particles"
import ParitclesConfig from "./data/particles.json";

const particlesJS = window.particlesJS;

export default class extends Controller {
	static targets = [
		'confirmationLink',
		'secondaryForm',
		'nav',
		'signUp',
		'pageBody'
	]

	connect() {
		if(document.getElementById('particles-js')) {
			window.onload = function () {
				particlesJS('particles-js', ParitclesConfig);
			}
		}

		if (localStorage.getItem('showConfirm') === 'true') {
			if (this.hasConfirmationLinkTarget) this.confirmationLinkTarget.classList.add('is-active');
		}

    this.setupRecaptca()
    this.token = false;

    this.form = document.getElementById('new_user');
    if (this.form) {
      this.form.onsubmit = this.submit.bind(this)
    }
	}

  submit(event) {
    console.log(event)
    const isRecaptcha = document.getElementById('enroll-now-recaptcha');

    if (!this.token && isRecaptcha) {
      event.preventDefault();
      return false;
    }
  }

  setupRecaptca() {
    window.showSubmit = this.showSubmit.bind(this);
    this.submitButton = document.getElementById('enroll-now-submit');
    if (this.submitButton) {
      this.submitButton.style.display = 'none';
    }

    this.recaptcha = document.querySelectorAll('.g-recaptcha');

    this.recaptcha.forEach((captcha) => {
      captcha.setAttribute('data-callback','showSubmit')
    });
  }

	isFocused() {
		setTimeout(() => {
			if (window.location.pathname === '/login') return;
			this.secondaryFormTarget.classList.add('is-active');
		}, 300);
	}

	scroll() {
		scrollIt(
			this.navTarget,
			600,
			'easeOutQuad'
		);

		setTimeout(() => this.signUpTarget.focus(), 600);

		setTimeout(() => {
			this.secondaryFormTarget.classList.add('is-active');
		}, 800);
	}

	showConfirm() {
		localStorage.setItem('showConfirm', true);
	}

  showSubmit(token) {
    console.log(this, token)


    if (token) {
      this.token = token;
      document.getElementById('enroll-now-submit').style.display = 'inline-block';
      this.tmpDisableSubmitForSpam();
      document.getElementById('enroll-now-recaptcha').style.display = 'none';

      if (window.dataLayer) {
        window.dataLayer.push(
          {'gtm.element': {
            dataset: {
            category: 'Enroll Sign Up', action: 'Passed recaptcha', label: 'recaptcha', value: 'I\'m Human'}
          }, 'event' : 'trackEvent'}
        )
      }
    }
	}

  tmpDisableSubmitForSpam() {
    let submitBtn = document.getElementById('enroll-now-submit');
    submitBtn.setAttribute('disabled', true);
    setTimeout(() => {
      submitBtn.removeAttribute('disabled');
    }, 1000);
  }

	cookiesConsentClick() {
		this.pageBodyTarget.classList.remove('cookies-not-allowed');
	}
}

import { Controller } from 'stimulus';
import fetchMixin from '../dashboard/signUp/fetchMixin.js';
import Noty from 'noty';
import Tippy from 'tippy.js';

export default class extends Controller {
  static targets = [
    'discardQuestion',
    'editAnswers',
    'saveAnswers',
    'cancelEdit',
    'choicesForm',
    'answerRow',
    'closedRow',
    'searchInput',
    'choicesForm',
    'choiceOption',
    'answer',
    'answers',
    'discardModal',
  ];

  connect() {
    Tippy('[data-tippy-content]', {
      animation: 'fade',
      arrow: true,
    });
  }

  clearSearch() {
    window.location.replace(location.pathname);
  }

  handleSaveAnswers() {
    if (this.getSelectedCount() <= 0) {
      this.saveAnswersTarget.disabled = true;
      return this.showNoty(
        'Please make a selection or remove question!',
        'error'
      );
    }
    if (parseInt(this.choicesFormTarget.dataset.selectionLimit) === 1) {
      this.answerId = this.answerTarget.dataset.answerId;
      this.updateAnswer();
    } else {
      this.updateMultiSelect();
    }
    this.closeQuestion();
  }

  handleEdit(event) {
    let questionId = this.editAnswersTarget.dataset.questionId;
    this.newChoiceIds = [];
    this.deletedAnswerIds = [];

    this.saveAnswersTarget.disabled = true;
    this.saveAnswersTarget.classList.add('disabled');
    this.closedRowTarget.classList.remove('is-closed');
    this.closedRowTarget.classList.add('is-active');
    this.closedRowTarget.parentElement.classList.remove('is-closed');
    this.closedRowTarget.parentElement.classList.add('is-active');
    this.setOtherRowsInactive(`closedRow-${questionId}`);
  }

  closeQuestion() {
    let questionId = this.cancelEditTarget.dataset.questionId;
    this.closedRowTarget.classList.remove('is-active');
    this.closedRowTarget.parentElement.classList.remove('is-active');
    this.unsetRowsInactive();
    this.closedRowTarget.classList.add('is-closed');
    this.closedRowTarget.parentElement.classList.add('is-closed');
    this.closedRowTarget.classList.add('is-animating');
    this.resetEditingAnswersVars();
    // add class to prevent content jumping while animating
    setTimeout(() => {
      this.closedRowTarget.classList.remove('is-animating');
    }, 250);
  }

  openDiscardModal(event) {
    this.discardModalTarget.classList.add('is-open');
    document.getElementById('app-main').classList.add('body-no-scroll');
  }

  closeDiscardModal(event) {
    this.discardModalTarget.classList.remove('is-open');
    document.getElementById('app-main').classList.remove('body-no-scroll');
  }

  disableClick(event) {
    event.stopPropagation();
  }

  handleDiscard(event) {
    let answersIds = this.savedAnswersIds();
    let questionId = this.discardQuestionTarget.dataset.questionId;
    this.discardQuestion(answersIds);
    this.createSkippedQuestion(questionId);

    document.getElementById('app-main').classList.remove('body-no-scroll');
  }

  handleUpdateAnswer(event) {
    this.saveAnswersTarget.disabled = false;
    this.saveAnswersTarget.classList.remove('disabled');
    let choiceOptionTarget = event.target;
    this.choiceId = choiceOptionTarget.dataset.choiceId;

    if (parseInt(this.choicesFormTarget.dataset.selectionLimit) > 1) {
      if (
        this.getSelectedCount() > this.choicesFormTarget.dataset.selectionLimit
      ) {
        event.preventDefault();
        this.showNoty('Max selection limit reached!', 'error');
      }
    }
  }

  updateMultiSelect() {
    this.choiceOptionTargets.forEach(choiceOption => {
      let choiceId = choiceOption.dataset.choiceId;

      if (choiceOption.checked) {
        if (!this.isExistingAnswer(choiceId)) this.newChoiceIds.push(choiceId);
      } else {
        let answerTarget = this.answerTargets.find(
          answerTarget => answerTarget.dataset.choiceId === choiceId
        );
        if (answerTarget) {
          let answerId = answerTarget.dataset.answerId;
          this.deletedAnswerIds.push(answerId);
        }
      }
    });

    this.saveMultiSelect();
  }

  saveMultiSelect() {
    let answers = [];
    let promises = [];

    this.newChoiceIds.forEach(choiceId => {
      answers.push({
        question_id: this.choicesFormTarget.dataset.questionId,
        choice_id: choiceId,
      });
    });

    if (answers.length > 0) promises.push(this.createAnswers(answers));

    this.deletedAnswerIds.forEach(answerId => {
      promises.push(this.deleteAnswer(answerId, { removeAnswerElement: true }));
    });

    Promise.all(promises)
      .then(() => {
        this.showNoty('Poll Answer Saved!', 'success');
        this.resetEditingAnswersVars();
        this.unsetRowsInactive();
      })
      .catch(error => {
        console.log('error: ', error);
        this.unsetRowsInactive();
        this.showNoty('Poll Answer could not be Updated!', 'error');
      });
  }

  updateAnswer() {
    fetchMixin.methods
      .patch(`/api/v2/answers/${this.answerId}`, { choice_id: this.choiceId })
      .then(response => response.json())
      .then(json => {
        this.answerTarget.dataset.choiceId = json.answer.choice_id;
        this.answerTarget.innerHTML = json.choice_value;
        this.unsetRowsInactive();
        this.showNoty('Poll Answer Updated!', 'success');
      })
      .catch(error => {
        console.log('error: ', error);
        this.unsetRowsInactive();
        this.showNoty('Poll Answer could not be Updated!', 'error');
      });
  }

  createAnswers(answers) {
    fetchMixin.methods
      .post('/api/v2/answers', { answers: answers })
      .then(response => response.json())
      .then(json => {
        let answers = json;
        this.updateDomWithAnswers(answers);
      })
      .catch(error => console.log('error: ', error));
  }

  updateDomWithAnswers(answers) {
    let elements = answers.map(answer => {
      return this.createAnswerElement(answer);
    });
    elements.forEach(element => this.answersTarget.append(element));
  }

  createSkippedQuestion(questionId) {
    fetchMixin.methods
      .post('/api/v2/skipped_questions', { question_id: questionId })
      .then(response => response.json())
      .catch(error => console.log('error: ', error));
  }

  deleteAnswer(answerId, data = {}) {
    fetchMixin.methods
      .delete(`/api/v2/answers/${answerId}`)
      .then(response => response.json())
      .then(json => {
        if (data.removeAnswerElement)
          document.getElementById(`answers-${json.answer_id}`).remove();
      })
      .catch(error => console.log('error: ', error));
  }

  discardQuestion(answersIds) {
    let promises = [];
    answersIds.forEach(answerId => {
      promises.push(this.deleteAnswer(answerId));
    });

    Promise.all(promises)
      .then(() => {
        this.answerRowTarget.remove();
        this.unsetRowsInactive();
        this.resetEditingAnswersVars();
        this.showNoty('Question Deleted!', 'success');
      })
      .catch(error => {
        console.log('error: ', error);
        this.unsetRowsInactive();
        // TODO: Show noty once at end of deleting all answers for multi-select
        this.showNoty('Question could not be Deleted!', 'error');
      });
  }

  createAnswerElement(answer) {
    let element = document.createElement('p');
    element.id = `answers-${answer.id}`;
    element.dataset.target = 'answers.answer';
    element.dataset.answerId = answer.id;
    element.dataset.questionId = answer.question_id;
    element.dataset.choiceId = answer.choice_id;
    element.innerHTML = answer.choice_value;
    return element;
  }

  savedAnswerChoicesIds() {
    return this.answerTargets.map(
      answerTarget => answerTarget.dataset.choiceId
    );
  }

  savedAnswersIds() {
    return this.answerTargets.map(
      answerTarget => answerTarget.dataset.answerId
    );
  }

  isExistingAnswer(choiceId) {
    return this.savedAnswerChoicesIds().includes(choiceId);
  }

  selectedAnswerChoices() {
    return this.choiceOptionTargets.map(
      choiceOption => choiceOption.dataset.choiceId
    );
  }

  resetEditingAnswersVars() {
    this.answerId = null;
    this.questionId = null;
    this.choiceId = null;
    this.newChoiceIds = [];
    this.deletedAnswerIds = [];
  }

  showNoty(message, type) {
    new Noty({
      type: type,
      theme: 'relax',
      layout: 'topRight',
      container: '.noty-container',
      text: message,
      timeout: '4000',
      closeWith: ['click'],
      killer: true,
    }).show();
  }

  markSelected() {
    this.savedChoiceIds = this.savedAnswerChoicesIds();
    this.choiceOptionTargets.forEach(target => {
      if (this.savedChoiceIds.includes(target.dataset.choiceId)) {
        target.checked = true;
      }
    });
  }

  getSelectedCount() {
    let selectedCount = 0;
    this.choiceOptionTargets.forEach(choice => {
      if (choice.checked) selectedCount++;
    });
    return selectedCount;
  }

  setOtherRowsInactive(rowId) {
    document.querySelectorAll('.closed-row').forEach(row => {
      if (row.id !== rowId) {
        row.classList.add('is-inactive');
        if (row.classList.contains('is-active'))
          row.classList.remove('is-active');
        if (row.classList.contains('is-closed'))
          row.classList.remove('is-closed');
      }
    });
  }

  unsetRowsInactive() {
    document.querySelectorAll('.closed-row').forEach(row => {
      row.classList.remove('is-inactive');
    });
  }
}

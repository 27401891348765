import { Controller } from 'stimulus';
import Tribute from 'tributejs';
import { el, mount } from 'redom';
import Noty from 'noty';

export default class extends Controller {
  static targets = [
    'paypalSection',
    'paypalEmail',
    'paypalError',
    'confirmCode',
    'verifiedContinue',
    'phoneNumber',
    'countryCode',
    'sendVerificationButton',
    'verificationCode',
    'formSubmitButton',
    'locationCountry',
    'locationState',
    'locationTimezone',
    'referralEmail',
    'birthYear',
    'jobTitle',
    'spinner',
    'languageCheckbox',
    'languageSelect',
    'flashMessages',
  ];

  connect() {
    this.handleNotifications();

    this.tributeEventListener = event => {
      let input = event.target;
      let matchedItem = event.detail.item;
      this.addLanguage(matchedItem.original);
      this.nonPreferredLanguages = this.nonPreferredLanguages.filter(
        language => language.name != matchedItem.original.name
      );
      this.tributeAutoCompleteLanguage.appendCurrent(
        this.nonPreferredLanguages,
        true
      );

      input.innerHTML = '';
      input.focus();
    };

    this.validatingPaypal = false;
    this.invalidatedPaypal = false;
    this.validatedPaypal = false;
    this.phoneVerificationSent = false;
    this.phoneIsVerified = false;

    if (this.locationCountryTarget.value === '') {
      this.hideOtherInputs();
    }

    this.getNonPreferredLanguages();
  }

  handleNotifications() {
    let errors = JSON.parse(this.flashMessagesTarget.dataset.errors);
    if (errors) {
      this.showNoty('Error Updating Your Profile!', 'error');
      return this.scrollToFirstError(errors);
    }

    let message = JSON.parse(this.flashMessagesTarget.dataset.message);
    if (message && message.success) {
      return this.showNoty(message.success, 'success');
    }
    if (message && message.warning) {
      return this.showNoty(message.warning, 'warning');
    }
  }

  disconnect() {
    if (this.tributeAutoCompleteLanguage) {
      this.languageSelectTarget.removeEventListener(
        'tribute-replaced',
        this.tributeEventListener
      );
      this.tributeAutoCompleteLanguage.detach(this.languageSelectTarget);
    }
  }

  disableIfEnglish(event) {
    if (event.target.nextElementSibling.innerHTML === 'English') {
      event.preventDefault();
    }
  }

  handlePaypalEmailValidation(event) {
    this.validatingPaypal = true;
    this.spinnerTarget.classList.add('is-active');
    this.formSubmitButtonTarget.disabled = true;
    if (this.validateTimer) clearTimeout(this.validateTimer);

    this.validateTimer = setTimeout(() => {
      this.post('/api/v2/users/validate_paypal', {
        paypal_email: this.paypalEmailTarget.value,
      })
        .then(json => {
          this.spinnerTarget.classList.remove('is-active');

          if (json.errors) {
            if (
              json.errors.includes(
                'Paypal email address is already registered.'
              )
            ) {
              this.validationError({ paypalTaken: true });
            } else {
              this.validationError({ serverError: true });
            }
            return;
          }

          if (json.table.success) {
            this.validatingPaypal = false;
            this.showPayPalValid();
            this.validatedPaypal = true;
            this.invalidatedPaypal = false;
            if (this.phoneIsVerified || !this.phoneVerificationSent) {
              this.formSubmitButtonTarget.disabled = false;
            }
          } else {
            this.validationError({ notRegistered: true });
          }
        })
        .catch(error => {
          console.log('error: ', error);
          this.validationError({ serverError: true });
        });
    }, 3000);
  }

  handleSend(event) {
    this.formSubmitButtonTarget.disabled = true;
    this.verificationCodeTarget.value = '';
    this.post('/api/v2/users/send_verification', {
      phone_number: this.phoneNumberTarget.value,
      phone_country_code: this.countryCodeTarget.value,
    })
      .then(json => {
        if (!json.errors) {
          this.phoneVerificationSent = true;
          this.sendVerificationButtonTarget.classList.add('hide');
          this.confirmCodeTarget.classList.remove('hide');
        }
      })
      .catch(error => {
        console.log('error:', error);
      });
  }

  handleVerify(event) {
    this.post('/api/v2/users/verify_phone', {
      otp_auth_token: this.verificationCodeTarget.value,
    })
      .then(json => {
        if (!json.errors) {
          this.phoneIsVerified = true;
          this.confirmCodeTarget.classList.add('hide');
          this.verifiedContinueTarget.classList.remove('hide');
          if (
            !this.validatingPaypal &&
            (this.validatedPaypal || !this.invalidatedPaypal)
          ) {
            this.formSubmitButtonTarget.disabled = false;
          }
        } else {
          this.phoneIsVerified = false;
        }
      })
      .catch(error => {
        console.log('error:', error);
      });
  }

  resetPhoneVerification() {
    // this.formSubmitButtonTarget.disabled = true;
    // this.sendVerificationButtonTarget.classList.remove('hide');
    // this.confirmCodeTarget.classList.add('hide');
    // this.verifiedContinueTarget.classList.add('hide');
  }

  setupTribute() {
    if (this.tributeAutoCompleteLanguage) return;

    this.tributeAutoCompleteLanguage = new Tribute({
      autocompleteMode: true,
      lookup: 'name',
      values: this.nonPreferredLanguages,
      menuItemLimit: 25,
      selectTemplate: function(item) {
        if (typeof item === 'undefined') return null;
        if (this.range.isContentEditable(this.current.element)) {
          return `<span contenteditable="false"><a>${item.original.name}</a></span>`;
        }
        return item.original.value;
      },
      menuItemTemplate: function(item) {
        return item.string;
      },
    });

    this.tributeAutoCompleteLanguage.attach(this.languageSelectTarget);
    this.languageSelectTarget.addEventListener(
      'tribute-replaced',
      this.tributeEventListener
    );
  }

  addLanguage(languageObject) {
    let checkbox = el('input', {
      type: 'checkbox',
      checked: 'checked',
      value: languageObject.id,
      name: 'user[language_ids][]',
      id: `user_language_ids_${languageObject.id}`,
    });
    let label = el(
      'label',
      { for: `user_language_ids_${languageObject.id}` },
      languageObject.name
    );

    let element = el('div.cell', checkbox, label);
    let container = document.getElementById('nonPreferredLanguages');
    mount(container, element);
  }

  getNonPreferredLanguages() {
    this.get('/api/v2/languages?non_preferred_languages=true')
      .then(json => {
        this.nonPreferredLanguages = json;
        this.setupTribute();
      })
      .catch(error => {
        console.log('error:', error);
      });
  }

  post(url = '', data = {}) {
    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: this.requestHeaders(),
      body: JSON.stringify(data),
    }).then(response => response.json());
  }

  get(url = '') {
    return fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: this.requestHeaders(),
    }).then(response => response.json());
  }

  requestHeaders() {
    return new Headers({
      'x-csrf-token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
      'content-type': 'application/json',
    });
  }

  showPayPalValid() {
    this.paypalSectionTarget.classList.add('validated');
    this.paypalSectionTarget.classList.remove('has-error');
    this.paypalErrorTarget.classList.remove('is-visible');
  }

  showPayPalInvalid(data) {
    this.paypalSectionTarget.classList.remove('validated');
    this.paypalSectionTarget.classList.add('has-error');
    this.paypalErrorTarget.classList.add('is-visible');

    if (data.notRegistered) {
      this.paypalErrorTarget.innerText =
        'Error: This email address is not recoginzied by PayPal';
    }
    if (data.paypalTaken) {
      this.paypalErrorTarget.innerText =
        'Error: Paypal email address is already registered';
    }
    if (data.serverError) {
      this.paypalErrorTarget.innerText =
        'Error: A server error occured. Please try again';
    }
  }

  validationError(data = {}) {
    this.showPayPalInvalid(data);
    this.validatingPaypal = false;
    this.invalidatedPaypal = true;
    this.validatedPaypal = false;
  }

  handleReferralClick() {
    // TODO: Are we doing anything with referalls on the profile page?
  }

  countySelectEvent(event) {
    if (event.target.value) {
      this.updateSelect(this.locationStateTarget, 'state');
      this.updateSelect(this.locationTimezoneTarget, 'timezone');
      this.locationStateTarget.classList.add('is-active');
      this.locationTimezoneTarget.classList.remove('is-active');
    } else {
      this.hideOtherInputs();
    }
  }

  updateSelect(input, type) {
    input.classList.remove('is-active');
    this.get(
      `/api/v2/languages/${type}s?alpha2=${this.locationCountryTarget.value}`
    ).then(json => {
      this.emptySelect(input);
      this.buildSelect(input, json);
      if (json.length) this.locationStateTarget.classList.add('is-active');
      if (!json.length) ithis.locationStateTarget.classList.add('is-active');
      input.value = '';
    });
  }

  emptySelect(input) {
    let options = [...input.children];
    options.forEach(option => {
      if (option.value) input.removeChild(option);
    });
  }

  buildSelect(input, json) {
    for (let i = 0; i < json.length; i++) {
      let option = el('option', {
        value: json[i].value,
        innerHTML: json[i].label,
      });

      mount(input, option);
    }
  }

  hideOtherInputs() {
    this.locationStateTarget.classList.remove('is-active');
  }

  scrollToFirstError(errors) {
    if (errors.birth_year) return this.smoothScroll(this.birthYearTarget);
    if (errors.job_title) return this.smoothScroll(this.jobTitleTarget);
  }

  smoothScroll(element) {
    setTimeout(() => {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 100);
  }

  showNoty(message, type) {
    new Noty({
      type: type,
      theme: 'relax',
      layout: 'topRight',
      container: '.noty-container',
      text: message,
      timeout: '4000',
      closeWith: ['click'],
      killer: true,
    }).show();
  }

  disableSubmitonEnter(event) {
    if (
      event.key === 13 ||
      event.keyIdentifier === 13 ||
      event.keyCode === 13
    ) {
      event.preventDefault();
      return false;
    }
  }
}

import { Controller } from 'stimulus';
import ActionCable from 'actioncable';
import fetchMixin from '../dashboard/signUp/fetchMixin';

export default class extends Controller {
  static targets = [
    'testLink',
    'navWrapper',
    'metaData',
    'updateBadge',
    'updateBadgeMobile',
    'avatarNotification',
    'hamburgerNotification',
  ];

  initialize() {
    this.userId = this.data.get('userId');
    this.cable = ActionCable.createConsumer(
      document
        .querySelector('meta[name="action-cable-url"]')
        .getAttribute('content')
    );
  }

  connect() {
    this.handleCheckUpdateStatus();
    this.tests = [];
    // fetch queued tests for user
    fetchMixin.methods
      .get('/api/v2/app_tests')
      .catch(error => console.log('error:', error))
      .then(response => response.json())
      .then(json => {
        this.tests = json;
        if (this.tests.length) this.setTestLinkActive();
      });

    this.createCableSubscription();
  }

  createCableSubscription() {
    this.UserUpdateChannel = this.cable.subscriptions.create(
      'UserUpdateChannel',
      {
        received: response => {
          if (!response || response.event === 'test_click_nav') return;

          if (response.event === 'new_test') {
            let test = {
              id: response.test_id,
              take_url: response.take_url,
              event: response.event,
            };
            this.tests.push(test);
            this.setTestLinkActive();
          }

          if (response.event === 'test_click_vue') {
            this.tests = this.tests.filter(
              test => test.id !== response.test_id
            );
            this.checkIfTestsEmpty();
          }
        },
      }
    );
  }

  setTestLinkActive() {
    this.testLinkTarget.classList.add('is-active');
    this.metaDataTarget.classList.add('hide-small');
  }

  setTestLinkInactive() {
    this.testLinkTarget.classList.remove('is-active');
    this.metaDataTarget.classList.remove('hide-small');
  }

  openTestLink(event) {
    event.preventDefault();
    let nextTest = this.tests.shift();
    window.open(nextTest.take_url);
    this.checkIfTestsEmpty();
    // next test is either a fetched instance of test or mapped values from an actioncable response.

    this.UserUpdateChannel.perform('test_click_nav', { test_id: nextTest.id });
  }

  checkIfTestsEmpty() {
    if (this.tests.length === 0) this.setTestLinkInactive();
  }

  clearLocalProgress() {
    localStorage.removeItem('progress');
  }

  handleCheckUpdateStatus() {
    // use new localstorage key to replace 'viewedUpdate' for next product update
    this.viewedUpdate = localStorage.getItem('viewedUpdate');
    if (!this.viewedUpdate) {
      this.setUpdatesState('add');
    }
  }

  handleViewedUpdate() {
    localStorage.setItem('viewedUpdate', true);
    this.setUpdatesState('remove');
  }

  setUpdatesState(action) {
    this.updateBadgeTarget.classList[action]('is-active');
    this.updateBadgeMobileTarget.classList[action]('is-active');
    this.avatarNotificationTarget.classList[action]('is-active');
    this.hamburgerNotificationTarget.classList[action]('is-active');
  }
}

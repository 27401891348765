import { Controller } from 'stimulus';
import * as ActiveStorage from 'activestorage';
// import { DirectUpload } from 'activestorage'
import Noty from 'noty';
import fetchMixin from '../dashboard/signUp/fetchMixin.js';

export default class extends Controller {
  static targets = [
    'cancelAccountModal',
    'settingsForm',
    'modalOverlay',
    'modalClose',
    'cancelLink',
    'keepMyAccount',
    'flashMessages',
    'contactInfo',
    'passwordReset',
    'avatarWrapper',
    'avatarFile',
    'avatarImage',
    'avatarUpload',
    'avatarPlaceholder',
    'cancelError',
    'realSubmitButton',
    'currentPassword',
    'newPassword',
    'passwordConfirmation',
    'currentPasswordMissing',
    'newPasswordMissing',
    'passwordConfirmationMissing',
    'passwordMismatch',
    'passwordChangeSuccess'
  ];

  initialize() {
    // preload avatar image
    this.createImagePromise().then(() => {
      this.avatarWrapperTarget.classList.remove('hide');
    });
  }

  connect() {
    ActiveStorage.start();
    this.handleNotifications();
    this.bindAvatarListeners();
  }

  postCancelAccount() {
    fetchMixin.methods
      .post('/api/v2/users/cancel_account')
      .then(json => {
        if (json.status === 200) {
          window.location.pathname = '/cancel-account-confirmation';
        } else {
          this.closeModal();
          this.cancelErrorTarget.classList.add('is-visible');
        }
      })
      .catch(error => {
        console.log('error:', error);
      });
  }

  createImagePromise() {
    let image = new Image();
    image.src = this.avatarImageTarget.src;

    let promise = new Promise((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = () => reject();
    });
    return promise;
  }

  handleNotifications() {
    let errors = JSON.parse(this.flashMessagesTarget.dataset.errors);
    if (errors) {
      return this.scrollToFirstError(errors);
    }

    let message = JSON.parse(this.flashMessagesTarget.dataset.message);
    if (message && message.success) {
      return this.showNoty(message.success, 'success');
    }
    if (message && message.warning) {
      return this.showNoty(message.warning, 'warning');
    }
  }

  imageAttached() {
    this.realSubmitButtonTarget.click();
    // TODO: See if direct upload option works better than submitting the form.
    //       Could be done after 2.0 release
    // const file = this.avatarFileTarget.files[0];
    // this.uploadFile(file);
  }

  // uploadFile(file) {
  //   const url = this.avatarFileTarget.dataset.directUploadUrl;
  //   const upload = new DirectUpload(file, url)
  //
  //   upload.create((error, blob) => {
  //     if (error) {
  //       console.log('upload error', error)
  //     } else {
  //       console.log('blob', blob)
  //       const hiddenField = document.createElement('input');
  //       hiddenField.setAttribute('type', 'hidden');
  //       hiddenField.setAttribute('value', blob.signed_id);
  //       console.log(hiddenField)
  //       document.querySelector('form').appendChild(hiddenField)
  //     }
  //   });
  // }

  bindAvatarListeners() {
    this.avatarListener('initialize', event => {
      this.avatarWrapperTarget.classList.add('hide');
      this.avatarUploadTarget.classList.remove('hide');
    });
    this.avatarListener('start', event => {
      // console.log('upload started', event.detail)
    });

    this.avatarListener('progress', event => {
      const progress = event.detail.progress;
      const progressElement = document.getElementById('progressFill');
      progressElement.style.width = `${progress}%`;
      // console.log('upload progress:', event.detail.progress)
    });

    this.avatarListener('error', event => {
      // console.log('upload error:', event.detail.error)
      event.preventDefault();
      const error = event.detail.error;
      const progressElement = document.getElementById('progressFill');
      progressElement.classList.add('direct-upload-error');
      progressElement.setAttribute('title', error);
      this.avatarWrapperTarget.classList.remove('hide');
      this.avatarUploadTarget.classList.add('hide');
    });

    this.avatarListener('end', event => {
      // console.log('upload end:', event.detail.file)
      const progressElement = document.getElementById('progressFill');
      this.avatarPlaceholderTarget.classList.add('upload-complete');
    });
  }

  avatarListener(event, callback) {
    this.avatarFileTarget.addEventListener(`direct-upload:${event}`, callback);
  }

  handleShowCancelModal() {
    this.cancelAccountModalTarget.style.display = 'block';
    this.modalOverlayTarget.style.display = 'block';

    let body = document.querySelector('body');
    body.classList.add('body-no-scroll');
  }

  handleCancelModalClick(event) {
    event.stopPropagation();
  }

  handleExitCancelModal(event) {
    event.preventDefault();
    this.closeModal();
    body.classList.remove('body-no-scroll');
  }

  closeModal() {
    this.cancelAccountModalTarget.style.display = 'none';
    this.modalOverlayTarget.style.display = 'none';
  }

  scrollToFirstError(errors) {
    if (errors.first_name || errors.last_name || errors.email) {
      this.showNoty('Failed to update Contact Information', 'error');
      return this.smoothScroll(this.contactInfoTarget);
    }
    if (
      errors.current_password ||
      errors.password ||
      errors.password_confirmation
    ) {
      this.showNoty('Failed to update Password', 'error');
      return this.smoothScroll(this.passwordResetTarget);
    }
  }

  smoothScroll(element) {
    setTimeout(() => {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 100);
  }

  showNoty(message, type) {
    new Noty({
      type: type,
      theme: 'relax',
      layout: 'topRight',
      container: '.noty-container',
      text: message,
      timeout: '4000',
      closeWith: ['click'],
      killer: true,
    }).show();
  }

  handleSave() {
    this.validateForm();
  }

  validateForm() {
    this.formInvalid = false;
    if (this.hasPasswordChangeSuccessTarget) this.passwordChangeSuccessTarget.classList.add('hide');
    if (this.newPasswordTarget.value || this.passwordConfirmationTarget.value) {
      this.validateField('currentPassword');
      this.validateField('newPassword');
      this.validateField('passwordConfirmation');

      if (this.newPasswordTarget.value && this.passwordConfirmationTarget.value) {
        this.validatePasswordsMatch();
      }

    } else {
      this.clearValidationErrors();
    }

    if (!this.formInvalid) {
      this.realSubmitButtonTarget.click();
    } else {
      this.smoothScroll(this.passwordResetTarget);
    }
  }

  validateField(field) {
    if (this[`${field}Target`].value) {
      this[`${field}MissingTarget`].classList.remove('is-visible');
    } else {
      this[`${field}MissingTarget`].classList.add('is-visible');
      this.formInvalid = true;
    }
  }

  clearValidationErrors() {
    this.currentPasswordMissingTarget.classList.remove('is-visible');
    this.newPasswordMissingTarget.classList.remove('is-visible');
    this.passwordConfirmationMissingTarget.classList.remove('is-visible');
    this.passwordMismatchTarget.classList.remove('is-visible');
  }

  validatePasswordsMatch() {
    if (this.newPasswordTarget.value !== this.passwordConfirmationTarget.value) {
      this.passwordMismatchTarget.classList.add('is-visible');
      this.formInvalid = true;
    } else {
      this.passwordMismatchTarget.classList.remove('is-visible');
    }
  }

  disableSubmitonEnter(event) {
    if (
      event.key === 13 ||
      event.keyIdentifier === 13 ||
      event.keyCode === 13
    ) {
      event.preventDefault();
      return false;
    }
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['statusFilter', 'industryFilter'];

  connect() {
    this.url = new URL(window.location.href);
    this.searchParams = this.url.searchParams;
    const filterParam = this.searchParams.get('segment_status');
    const industryParam = this.searchParams.get(
      'industries_segments[industry_id]'
    );
    if (this.hasStatusFilterTarget && filterParam) {
      this.statusFilterTarget.value = filterParam;
    }

    if (this.hasIndustryFilterTarget && industryParam) {
      this.industryFilterTarget.value = industryParam;
    }

    // if (!industryParam) {
    //   history.pushState(
    //     null,
    //     null,
    //     window.location.origin + window.location.pathname
    //   );
    // }
  }

  filterSegments(e) {
    this.searchParams.set('segment_status', e.target.value);
    this.url.pathname = '/admin/segments';
    window.location = decodeURIComponent(this.url.toString());
  }

  filterSegmentsbyIndustry(e) {
    this.searchParams.set('industries_segments[industry_id]', e.target.value);
    this.url.pathname = '/admin/segments';
    window.location = decodeURIComponent(this.url.toString());
  }

  previousSegment(e) {
    e.preventDefault();
    let urlParams = new URLSearchParams(window.location.search);
    let tab = urlParams.get('tab');
    window.location = `/admin/segments/${e.target.dataset.previousId}?tab=${tab}`;
  }

  nextSegment(e) {
    e.preventDefault();
    let urlParams = new URLSearchParams(window.location.search);
    let tab = urlParams.get('tab');
    window.location = `/admin/segments/${e.target.dataset.nextId}?tab=${tab}`;
  }
}

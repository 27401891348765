import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['statusFilter', 'filterType', 'priorityFilter'];

  connect() {
    this.url = new URL(window.location.href);
    this.searchParams = this.url.searchParams;
    const filterParam = this.searchParams.get('question_status');
    const priorityParam = this.searchParams.get('question_priority');
    const typeParam = this.searchParams.get('filter_type');
    if (this.hasStatusFilterTarget && filterParam) {
      this.statusFilterTarget.value = filterParam;
    }
    this.statusFilterTarget.classList.remove('hide');

    if (this.hasPriorityFilterTarget && priorityParam) {
      this.priorityFilterTarget.value = priorityParam;
    }
    this.priorityFilterTarget.classList.remove('hide');

    if (this.hasFilterTypeTarget && typeParam) {
      this.filterTypeTarget.value = typeParam;
      this.filterTypeTarget.classList.remove('hide');
    }
    if (this.hasFilterTypeTarget) {
      this.filterTypeTarget.classList.remove('hide');
    }
    if (this.url.pathname.split('/admin/question_attributes/')[1]) {
      history.pushState(
        null,
        null,
        window.location.origin + window.location.pathname
      );
    }
  }

  sortByAttribute(event) {
    let attribute = event.target.dataset.colName;
    if (this.searchParams.get('sort') === `-${attribute}`) {
      this.searchParams.set('sort', attribute);
    } else {
      this.searchParams.set('sort', `-${attribute}`);
    }
    window.location = decodeURIComponent(this.url.toString());
  }

  filterQuestions(e) {
    this.searchParams.set('question_status', e.target.value);
    this.url.pathname = '/admin/questions';
    window.location = decodeURIComponent(this.url.toString());
  }

  filterQuestionPriority(e) {
    this.searchParams.set('question_priority', e.target.value);
    this.url.pathname = '/admin/questions';
    window.location = decodeURIComponent(this.url.toString());
  }

  filterAttributeType(e) {
    this.searchParams.set('filter_type', e.target.value);
    this.url.pathname = 'admin/question_attributes';
    this.searchParams.delete('page');
    window.location = decodeURIComponent(this.url.toString());
  }
}
